import { PageProps } from 'gatsby';
import { FC } from 'react';
import PageTemplate from '~/templates/page';

const Page: FC<PageProps> = (props) => {
  return (
    // @ts-ignore - pageContext type might slightly mismatch here but works
    <PageTemplate
      location={props.location}
      pageContext={{
        ...props.pageContext,
        slug: 'unsubscribe',
        title: 'Unsubscribe',
        components: [
          {
            type: 'back_page_section',
            theme: 'white',
            hidden: false,
            kind: 'unsubscribe',
            heading: 'Unsubscribe',
          },
        ],
      }}
    />
  );
};

export default Page;
